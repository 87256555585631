.content {
  display: flex;
  gap: 12px;
  > div {
    &:nth-child(1) {
      svg {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;
        path {
          &:nth-child(1) {
            fill: #c4c8d0;
          }
          &:nth-child(2) {
            fill: #130f26;
          }
        }
      }
    }
    &:nth-child(2) {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        color: #232b3a;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
      > div {
        display: flex;
        gap: 8px;
        align-items: center;
        > span {
          font-size: 12px;
          font-weight: 400;
          color: #232b3a;
          line-height: 18px;
        }
      }
    }
  }
}
