.uploadButton {
  color: #668bc9;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  :global {
    .anticon {
      font-size: 24px;
    }
  }
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    svg {
      path {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
.wrapper {
  :global {
    .ant-upload-list-picture-card-container {
      position: relative;
      margin: 0 16px 16px 0;
      .ant-upload-list-item-info {
        &:before {
          display: none !important;
        }
      }
      .ant-upload-list-item-actions {
        display: none !important;
      }
    }
  }
}
.deleteIcon {
  position: absolute;
  right: -8px;
  top: -5px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    path {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
}
