@import 'src/assets/scss/mixins';

:global {
  .overlay {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100000;
    display: none;
    &.show {
      display: block;
    }
  }

  .overlay__content {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-height: 90%;
    max-width: 800px;
  }

  .overlay__close {
    position: absolute;
    right: 0;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    background-color: white;
    cursor: pointer;
    border: 3px solid black;
    font-size: 1.5rem;
    margin: -1rem;
    border-radius: 2rem;
    z-index: 100;
    box-sizing: content-box;
    top: 0;
  }

  .overlay__content video {
    width: 100% !important;
    height: 100% !important;
  }

  .overlay__content canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .scan-warning-modal {
    background: rgba(0, 0, 0, 0.5);
    display: none;
    &.show {
      display: block;
    }
  }
  .overlay__handler {
    position: absolute;
    left: 12px;
    top: 12px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    font-size: 14px;
    font-weight: 500;
    > span {
      cursor: pointer;
    }
  }
}

.actionBtn {
  position: absolute;
  right: 0;
  top: -40px;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
  svg {
    margin-left: 4px;
    path {
      fill: #005eff;
    }
  }
  &.headerHadBorder {
    top: -57px;
  }
  &:disabled {
    svg {
      path {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
  &.isSection4 {
    .section5Form {
      display: none;
    }
    .section4Form {
      display: flex;
    }
  }
  &.isSection5 {
    .section4Form {
      display: none;
    }
    .section5Form {
      display: flex;
    }
  }
}
.section5Form,
.section4Form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-style: normal;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8991a0;
  font-style: normal;
  word-break: break-word;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    li {
      img {
        width: 65px;
        height: 65px;
        border-radius: 8px;
      }
    }
  }
  .status {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #232b3a;
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
.modalWrapper {
  // min-width: 1000px;
  :global {
    .ant-modal-header {
      .ant-modal-title {
        padding-right: 30px;
        @include ellipsisLongText();
      }
    }
  }
}
.calPercent {
  color: #8991a0;
  &.isGreen {
    color: #1ec000;
  }
  &.isRed {
    color: #ed4646;
  }
}
.isYellow {
  color: #ff961e;
}
.referenceFiles {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid #ebedef;
    padding-bottom: 12px;
    > * {
      &:last-child {
        margin-left: auto;
        cursor: pointer;
      }
    }
    > span {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 2px;
      > span {
        word-break: break-all;
        &:nth-child(2) {
          color: #8991a0;
        }
      }
    }
    &:last-child {
      border: 0;
    }
    > svg {
      &:first-child {
        min-width: 33px;
      }
      &:last-child {
        min-width: 20px;
      }
    }
  }
}
.barcodeField {
  :global {
    .ant-form-item-label {
      width: 100%;
      > label {
        width: 100%;
      }
    }
  }
}
.barcodeLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > span:nth-child(2) {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 4px;
    > span {
      font-size: 14px;
      color: #005eff;
      font-weight: 500;
    }
  }
}
// .camera {
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   margin: auto;
// }
