.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: #3a4861;
  }
  button {
    border-radius: 4px;
    font-weight: 500;
  }
}
