.modalContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 32px;
      > .ant-form-item .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
.warningContent {
  display: block;
  width: 260px;
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #232b3a;
  margin-top: 8px;
}
