.wrapper {
  background: #f0f0f0;
  padding: 8px 12px;
  border-radius: 8px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    line-height: 16px;
    li {
      list-style: none;
      display: flex;
      gap: 8px;
      > span {
        &:nth-child(1) {
          display: inline-block;
          min-width: 120px;
          max-width: 120px;
          color: #8991a0;
        }
        &:nth-child(2) {
          display: flex;
          gap: 8px;
          justify-content: flex-end;
          flex: 1;
          &.aql {
            > span {
              display: inline-block;
              min-width: 32%;
              max-width: 32%;
            }
          }
        }
      }
    }
  }
}
