.uploadButton {
  color: #668bc9;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  :global {
    .anticon {
      font-size: 24px;
    }
  }
}
.wrapper {
  :global {
    .ant-upload-list-item-name {
      width: 1px;
    }
    .ant-upload-disabled {
      .ant-upload-drag-container {
        color: rgba(0, 0, 0, 0.25);
        svg {
          path {
            fill: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
.hide {
  display: none !important;
}
