.wrapper {
  display: flex;
  gap: 16px;
  > * {
    flex: 1;
  }
}
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 32px;
      > .ant-form-item .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
.contentDisplay {
  display: flex;
  flex-direction: column;
  gap: 16px;
  word-break: break-word;
  // margin-bottom: 16px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    li {
      img {
        width: 65px;
        height: 65px;
        border-radius: 8px;
      }
    }
  }
}
.warningContent {
  display: block;
  width: 260px;
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #232b3a;
  margin-top: 8px;
}
