.wrapper {
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  padding: 0 14px;
  gap: 12px;
  > * {
    flex: 1;
  }
}
