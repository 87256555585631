@import 'src/assets/scss/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  gap: 16px;
  padding: 16px 12px;
  textarea {
    min-height: 92px;
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &.borderHeader {
    .header {
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: 1px solid #ebedef;
      padding-bottom: 16px;
    }
  }
  &.actionOnHeader {
    // padding-bottom: 0;
    .header {
      position: relative;
      padding-right: 70px;
    }
    &:not(.editMode) {
      .content {
      }
    }
  }
  &.hasLimit {
    .childrenWrapper {
      width: 140px;
      max-width: 140px;
      min-width: 140px;
    }
  }
  &.onBox {
    border: 0;
    padding: 0;
    border-radius: 0;
  }
  &.required {
    .header {
      h2 {
        width: calc(100% - 80px);
      }
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #232b3a;
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
    > p {
      margin: 0;
      @include ellipsisLongText();
      display: inline-block;
    }
    > svg {
      cursor: pointer;
    }
  }
  > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #c4c8d0;
  }
}
.content {
  display: flex;
  justify-content: space-between;
  .childrenWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.sub {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 16px;
  > span {
    &:first-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8991a0;
    }
    &:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #1ec000;
    }
  }
  &.red {
    > span {
      &:nth-child(2) {
        color: #ed4646;
      }
    }
  }
}
.des {
  display: flex;
  flex-direction: column;
  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8991a0;
    > span {
      color: #ed4646;
    }
  }
}
.tag {
  margin-left: 8px;
}
.requiredMark {
  font-style: italic;
}
.limit {
  display: flex;
  margin-right: auto;
  margin-left: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8991a0;
}
.disabled {
  cursor: not-allowed !important;
  path {
    fill: rgba(0, 0, 0, 0.25);
  }
}
