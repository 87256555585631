.wrapper {
  padding: 39px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #8991a0;
  &.dark {
    color: #3a4861;
  }
}
